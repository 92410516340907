import { defineStore } from 'pinia'

interface UserPayloadInterface {
  username: string
  password: string
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    loading: false,
    me: useCookie('me', { default: () => null })
  }),
  getters: {
    isAdmin() {
      return this.me?.user_role.includes('administrator')
    },
    hasWishlist() {
      return this.me?.wishlist
    },
    countWishlist() {
      return this.me?.wishlist === '' ? 0 : this.me?.wishlist?.split(',').length
    }
  },
  actions: {
    removeMe() {
      const me = useCookie('me')
      me.value = null
      this.me = null
    },
    setMe(x) {
      const me = useCookie('me')
      me.value = x
      this.me = x
    },
    setWishlist(x) {
      const me = useCookie('me')
      this.me.wishlist = x
      me.value = this.me
    },
    setUpit(x) {
      const me = useCookie('me')
      this.me.upit = x
      me.value = this.me
    },
    async authenticateUser({ username, password }: UserPayloadInterface) {
      const config = useRuntimeConfig()
      const { data, pending } = await useFetch(config.public.CMS + '/wp-json/jwt-auth/v1/token', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: {
          username,
          password
        }
      })
      this.loading = pending.value

      if (data.value) {
        const me = useCookie('me')
        me.value = data?.value
        this.me = data?.value
      }
    }
  }
})
